<template>
    <div :class="{ section: !routersProp }" class="section-list">
        <div
            v-for="(router, route) in routers"
            :key="route"
            @click.capture="itemClickHandler"
        >
            <a
                v-if="router.children && router.permission"
                class="section-item"
                :class="[
                    isSelected(router.route) ? 'selected' : '',
                    isExpend(router.route) ? 'active' : ''
                ]"
                @click="toggleSectionList(router.route)"
            >
                <div class="section-grep" />
                <div class="section-word" v-text="router.text" />
                <div class="toggle-menu-icon" />
            </a>
            <router-link
                v-if="!router.children && router.permission && !router.isUrl"
                class="section-item"
                :class="[
                    isSelected(router.route) ? 'selected' : '',
                    isExpend(router.route) ? 'active' : ''
                ]"
                :to="{
                    path: router.route,
                    name: router.name,
                    params: router.params
                }"
            >
                <div class="section-grep" />
                <div class="section-word" v-text="router.text" />
            </router-link>
            <a
                v-if="!router.children && router.permission && router.isUrl"
                class="section-item"
                :class="[
                    isSelected(router.route) ? 'selected' : '',
                    isExpend(router.route) ? 'active' : ''
                ]"
                :href="router.route"
                @click="goToHandler"
            >
                <div class="section-grep" />
                <div class="section-word" v-text="router.text" />
            </a>
            <transition name="dropdown">
                <Section
                    v-if="router.children"
                    v-show="isExpend(router.route)"
                    :routersProp="router.children"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'Section',
    props: {
        routersProp: {
            type: Array,
            default: function () {
                return null
            }
        }
    },
    methods: {
        toggleSectionList: function (route) {
            if (this.expandedList.includes(route)) {
                _.pull(this.expandedList, route)
            } else {
                this.expandedList.push(route)
            }
            this.$store.state.isSectionClicked = false
        },
        setShowMobileMenu: function (isShow) {
            this.$store.commit('set', {
                showMobileMenu: isShow
            })
        },
        goToHandler() {
            this.setShowMobileMenu(false)
        },
        isExpend: function (route) {
            return this.expandedList.includes(route)
        },
        isSelected: function (route) {
            return this.routeChildren().includes(route)
        },
        isEnablePage: function (route) {
            return process.env.VUE_APP_ENABLE_PAGE.split(',').includes(route)
        },
        routeChildren: function () {
            return this.$route.path.split('/').reduce((memo, current) => {
                if (memo.length > 0)
                    memo.push(memo[memo.length - 1] + '/' + current)
                else memo.push(current)
                return memo
            }, [])
        },
        isReportPermission: function () {
            return (
                this.$isTitleCAup() ||
                this.$isAdmin() ||
                this.$store.getters.hasLeaderBgCodes
            )
        },
        itemClickHandler() {
            this.$store.state.isSectionClicked = true
        }
    },
    data() {
        return {
            expandedList: this.routeChildren(),
            routers: this.routersProp || [
                {
                    text: '業績查詢',
                    route: '/performance',
                    permission:
                        this.$hasPagePermission('Performance') &&
                        this.isEnablePage('performance'),
                    name: 'Performance'
                },
                {
                    text: '人力查詢',
                    route: '/organization',
                    permission:
                        this.$hasPagePermission('Organization') &&
                        this.isEnablePage('organization'),
                    name: 'Organization'
                },
                {
                    route: '/commission',
                    text: '佣金報表',
                    permission:
                        this.$hasPagePermission('Commission') &&
                        this.isEnablePage('commission'),
                    children: [
                        {
                            route: '/commission/history',
                            text: '佣金查詢',
                            permission: this.$hasPagePermission(
                                'CommissionHistory'
                            ),
                            name: 'CommissionHistory'
                        },
                        {
                            route: '/commission/underling',
                            text: '轄下佣金查詢',
                            permission: this.$hasPagePermission(
                                'CommissionUnderling'
                            ),
                            name: 'CommissionUnderling'
                        },
                        {
                            route: '/commission/admin',
                            text: '發佣作業',
                            permission:
                                this.$isAdmin() &&
                                this.$hasPagePermission('CommissionAdmin'),
                            children: [
                                {
                                    route: '/commission/admin/st',
                                    text: '事業部制',
                                    permission: this.$hasPagePermission(
                                        'CommissionAdminSt'
                                    ),
                                    name: 'CommissionAdmin',
                                    params: {
                                        mType: 'st'
                                    }
                                },
                                {
                                    route: '/commission/admin/ls',
                                    text: '系統制',
                                    permission: this.$hasPagePermission(
                                        'CommissionAdminLs'
                                    ),
                                    name: 'CommissionAdmin',
                                    params: {
                                        mType: 'ls'
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    route: '/report',
                    text: '業績報表',
                    permission:
                        this.$hasPagePermission('Report') &&
                        this.isEnablePage('report'),
                    children: [
                        {
                            route: '/report/annual',
                            text: '系統制繼續率發放資格',
                            name: 'ReportAnnual',
                            permission: this.$hasPagePermission('ReportAnnual')
                        },
                        {
                            route: '/report/detail',
                            text: '單位業績明細表',
                            name: 'ReportDetail',
                            permission:
                                this.$hasPagePermission('ReportDetail') &&
                                this.isReportPermission(),
                            params: {
                                mode: '1'
                            }
                        },
                        {
                            route: '/report/daily',
                            text: '當日業績速報表',
                            name: 'ReportDaily',
                            permission:
                                this.isReportPermission() &&
                                this.$hasPagePermission('ReportDaily')
                        },
                        {
                            route: '/report/contest',
                            text: '競賽業績查詢',
                            name: 'ReportContest',
                            permission: this.$hasPagePermission('ReportContest')
                        },
                        {
                            route: '/report/interval',
                            text: '單位業績查詢',
                            name: 'ReportInterval',
                            permission:
                                this.isReportPermission() &&
                                this.$hasPagePermission('ReportDetail')
                        }
                    ]
                },
                {
                    route: '/award',
                    text: '獎項查詢',
                    name: 'Award',
                    permission:
                        this.$hasPagePermission('Award') &&
                        this.isEnablePage('award')
                },
                {
                    route: '/tia',
                    text: '客戶服務',
                    permission:
                        this.$hasPagePermission('Tia') &&
                        this.isEnablePage('tia'),
                    children: [
                        {
                            route: '/tia/insurance',
                            text: '保單查詢',
                            name: 'TiaInsurance',
                            permission: this.$hasPagePermission('TiaInsurance')
                        },
                        {
                            route: '/tia/notification',
                            text: '通知查詢',
                            name: 'TiaNotification',
                            permission: this.$hasPagePermission(
                                'TiaNotification'
                            )
                        },
                        {
                            route: '/tia/customer',
                            text: '客戶查詢',
                            name: 'TiaCustomer',
                            permission: this.$hasPagePermission('TiaCustomer')
                        }
                    ]
                },
                {
                    route: '/backstage',
                    text: '後台設定',
                    permission:
                        this.$hasPagePermission('Backstage') &&
                        this.isEnablePage('backstage'),
                    children: [
                        {
                            route: '/backstage/product',
                            text: '商品批次管理',
                            name: 'BackstageProduct',
                            permission: this.$hasPagePermission(
                                'BackstageProduct'
                            )
                        },
                        {
                            route: '/backstage/report',
                            text: '全公司報表',
                            name: 'BackstageReport',
                            permission: this.$hasPagePermission(
                                'BackstageReport'
                            ),
                            params: {
                                mode: 'contest'
                            }
                        }
                    ]
                },
                {
                    route: '/product',
                    text: '商品資料庫',
                    permission:
                        this.$hasPagePermission('Product') &&
                        this.isEnablePage('product'),
                    children: [
                        {
                            route: '/product/receive',
                            text: '受理商品利益估算',
                            name: 'ProductReceive',
                            permission: this.$hasPagePermission(
                                'ProductReceive'
                            )
                        },
                        {
                            route: '/product/contract',
                            text: '有效契約利益計算',
                            name: 'ProductContract',
                            permission: this.$hasPagePermission(
                                'ProductContract'
                            )
                        },
                        {
                            route: '/product/summary',
                            text: '商品資料總表',
                            name: 'ProductSummary',
                            permission: this.$hasPagePermission(
                                'ProductSummary'
                            )
                        }
                    ]
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.section {
    width: $secondary-section-width;
    box-sizing: border-box;

    @media screen and (min-width: 768px) and (max-width: 1280px) {
        width: $third-section-width;
        display: none;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
        width: $third-section-width;
        display: none;
    }

    @media screen and (min-width: 0px) and (max-width: 575px) {
        width: $third-section-width;
        display: none;
    }
}

.section-list {
    padding-left: 20px;

    .section-word {
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .section-item {
        min-height: 40px;
    }
}

.section.section-list {
    & > div > .section-item {
        min-height: 50px;
    }
}

.section-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    .section-grep {
        height: 20px;
        width: 4px;
        background-color: $secondary-red;
        opacity: 0;
    }

    .section-word {
        color: $sixth-black;
        font-size: 16px;
        line-height: 22px;
        margin-left: 15px;
        height: 22px;
    }

    .toggle-menu-icon {
        height: 12px;
        width: 7px;
        margin-left: 18px;
        transition: transform 0.3s;
        transform: rotate(0deg);
    }

    &.active {
        .toggle-menu-icon {
            transition: transform 0.3s;
            transform: rotate(90deg);
        }
    }

    &.selected {
        .section-grep {
            opacity: 1;
        }

        :not(.not-visible) + .section-word {
            opacity: 1;
            font-weight: 600;
        }
    }
}

.section-icon {
    height: 22px;
    width: 22px;
    margin: 0 20px 0 0;
    align-self: center;

    @media screen and (min-width: 1281px) {
        display: none;
    }

    @media screen and (min-width: 992px) and (max-width: 1280px) {
        display: none;
    }
}
</style>
